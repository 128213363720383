@media only screen and (max-width:1460px)  {
body {
	font-size:12px;
}
.card-body .form-group label {
    font-size: 14px;
}
.dashboardpage .card .card-title {
    font-size: 14px;
}
.dashboardpage .list-group-item {
    font-size: 11px;
    margin: 5px 0 0 0;
}
.twocollist .list-group-item {
    width: 52%;
}
.twocollist .list-group-item:nth-child(odd) {
    width: 48%;
}
.dashboardpage .list-group-item span {
    font-size: 10px;
}
.mt-30 {
    margin-top: 20px;
}
.card-body {
    padding: 0.75rem;
}
.maptabs .btn-primary {
    font-size: 10px;
    line-height: 1.2;
}
.rbc-toolbar button {
    padding: .20rem 0.5rem;
    font-size: 10px;
}
.card-calendar .mb-5, .card-calendar .my-5 {
    margin-bottom: 1rem !important;
}
.rbc-toolbar .rbc-toolbar-label {
    font-size: 13px;
}
.recentactivity .imgbx img {
	width:30px;
}
.recentactivity .arrowimg img {
	width:18px;
}
.recentactivity .table th, .recentactivity .table td {
    padding: 0.5rem 5px;
}
.recentactivity .font-16 {
    font-size: 14px;
}
.tradeprocess .fixleft,
.domainname .fixleft {
    width: 40px;
    transform: translate(-50%,-80%);
}
.status-card .imagebx {
    width: 40px;
}
.sliderbx .carousel-control-prev, .sliderbx .carousel-control-next {
    top: 22%;
}
.slick-arrow {
	top: 28%;
	z-index: 9;
}
}

@media only screen and (max-width:991px)  {
header .col {
    text-align: center;
}
header .w275 {
    margin-bottom: 15px;
}
header .nav-toggler {
    float: right;
    font-size: 20px;
}
.headerlogo {
    margin: 15px auto 0;
}
h2, .h2 {
    font-size: 20px;
}
#main-wrapper[data-sidebartype='mini-sidebar'] .left-sidebar {
    left: -300px;
}
.left-sidebar.show-sidebar {
    left: 0 !important;
}
.left-sidebar {
    z-index: 100;
}
.pageheader .row > div + div {
    margin-top: 15px;
}
.bulkfield {
    padding: 10px;
}
}